import { createContext, useContext } from 'react';

interface IThemeContext {
  theme: string;
  setTheme: (theme: string) => void;
}

export const THEME_STORAGE_KEY = 'globalTheme';

export const AVAILABLE_THEMES = [
  // 'dark',
  // 'light',
  // 'light-blue',
  // 'dark-blue',
  // 'yellow',
  // 'dark-yellow'
  'dark-green',
];

const DEFAULT_THEME = 'dark-green';

export const saveTheme = (theme: string) => {
  localStorage.setItem(THEME_STORAGE_KEY, theme);
};

export const getTheme = () => {
  const savedTheme = localStorage.getItem(THEME_STORAGE_KEY);
  if (savedTheme && AVAILABLE_THEMES.includes(savedTheme)) {
    return savedTheme;
  }
  saveTheme(DEFAULT_THEME);
  return DEFAULT_THEME;
};

const initialThemeState = {
  theme: getTheme(),
  setTheme: saveTheme,
};

const ThemeContext = createContext<IThemeContext>(initialThemeState);
export const useThemeContext = () => useContext(ThemeContext);
export default ThemeContext;
