import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useResponsive } from 'hooks/useResponsive';
import { useCreateTVBetSessionMutation } from 'store/slots/slotsApi';
import Loader from 'components/shared/loader/Loader';

type AccessError = {
  data?: {
    detail?: string;
  };
  status?: number;
}

const fixLang = (lang: string | undefined) => {
  if (lang === 'uk') {
    return 'ua';
  }
  if (lang === 'kk') {
    return 'kz';
  }

  return lang;
};

const TVBet = () => {
  const { isMobile } = useResponsive();
  const { i18n, t } = useTranslation();
  const { gameId } = useParams();
  const [createSession, { data, isLoading, isSuccess, isError, error }] = useCreateTVBetSessionMutation();
  const language = fixLang(i18n.resolvedLanguage);

  useEffect(() => {
    if (data?.token && data?.clientId) {
      const script = document.createElement('script');

      script.onload = function () {
        // @ts-ignore
        new TvbetFrame({
          'lng': language,
          'clientId': `${data?.clientId}`,
          'token': data?.token,
          'server': 'https://tvbetframe14.com',
          'containerId': 'betgames__container',
          'game_id': gameId,
        });
      };

      script.type = 'text/javascript';
      script.id = 'tvbet_script';
      script.src = 'https://tvbetframe14.com/assets/frame.js';

      document.head.appendChild(script);
    }

    return () => {
      const betgames = document.getElementById('tvbet_script');
      betgames?.remove();
    };
  }, [data]);

  useEffect(() => {
    createSession();
  }, []);

  const errorDetails = error as AccessError;

  let errorDesc = 'Ошибка авторизации игры';
  if (isError && errorDetails?.data?.detail) {
    if (errorDetails?.data?.detail === 'Slots not available for this account') {
      errorDesc = 'Игра не доступна для вашего акканта';
    } else {
      errorDesc = errorDetails?.data?.detail;
    }
  }

  return (
    <div className={cn('betgames')}>
      <Breadcrumbs
        className='betgames__breadcrumbs'
        link="/games"
        title={t('games.list', 'Список игр')}
      />
      <h1 className='betgames__title'>TVBet</h1>
      <Loader wrapperClassName='betgames__spiner' />
      {isError && (
        <div className="betgames__error">
          <p className="betgames__error-title">
            {t('games.error.common', 'Произошла ошибка')}
          </p>
          <p>{errorDesc}</p>
        </div>
      )}
      <div className="betgames__container" id='betgames__container'></div>
    </div>
  );
};

export default TVBet;
