import i18n from 'i18n/i18n';
import tKickPreview from 'assets/twain-sport/twain-sport-t-kick.jpg';
import tBasketPreview from 'assets/twain-sport/twain-sport-t-basket.jpg';
import tKickPreviewWebp from 'assets/twain-sport/twain-sport-t-kick.webp';
import tBasketPreviewWebp from 'assets/twain-sport/twain-sport-t-basket.webp';
import betGamesPreviews from 'assets/betgames';
import tvBetPreviews from 'assets/tvbet';
import crashGameSkyward from 'assets/crash-games/card-crash-game.jpg';
import crashGameSkywardWebp from 'assets/crash-games/card-crash-game.webp';
import fastsportFootball from 'assets/fastsport/football@500х300.jpg';
import fastsportTennis from 'assets/fastsport/tennis@500х300.jpg';
import fastsportCyberFootball from 'assets/fastsport/efootball@500х300.jpg';

import type { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';

export enum NameSpace {
  BaseApi = 'baseApi',
  App = 'app',
  Auth = 'auth',
  Basket = 'basket',
  Line = 'line',
  Payments = 'payments',
  Slots = 'slots',
  User = 'user',
  Wallets = 'wallets',
  PartnerProgram = 'partnerProgram',
}

export const LINE_TYPES = ['line', 'live'];

export const availableLang = ['en'];

export type Social = 'vk' | 'telegram' | 'ok' | 'gmail' | 'yandex' | 'mail' | 'steam';
export type SignUpMethod = Social | 'fast';
export const availableSocial: Social[] = ['vk', 'telegram', 'ok', 'gmail', 'yandex', 'mail', 'steam'];

export const whatsappSuppotLink = '#';
export const whatsappLink = '#';
// export const domainName = window.location.host;
export const domainName = 'chezabet.ke';
export const logoText = 'CHEZABET';
export const TRANSACTIONS_ITEMS_PER_PAGE = 20;
export const BETS_ITEMS_PER_PAGE = 10;
export const FAV_ITEMS_PER_PAGE = 10;
export const COUPON_ITEMS_PER_PAGE = 10;

export const footerNavItems = [
  {
    groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
    items: [
      {
        name: i18n.t('footer.nav.item.play', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.terms', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.privacy', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.gaming', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.cookies', 'Правила'),
        link: '/rules'
      },
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      // {
      //   name: 'Слоты',
      //   link: '/slots'
      // },
    ]
  },
];

export const getFooterItems = () => [
  // {
  //   groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
  //   items: [
  //     {
  //       name: i18n.t('footer.nav.item.Bonus and actions', 'Бонусы и Акции'),
  //       link: '/promotions'
  //     },
  //     {
  //       name: i18n.t('footer.nav.item.play', 'Как играть'),
  //       link: '/terms'
  //     },
  //     {
  //       name: i18n.t('footer.nav.item.terms', 'Правила'),
  //       link: '/terms'
  //     },
  //     {
  //       name: i18n.t('footer.nav.item.privacy', 'Политика конфиденциальности'),
  //       link: '/privacy-policy'
  //     },
  //     {
  //       name: i18n.t('footer.nav.item.gaming', 'Правила'),
  //       link: '/terms'
  //     },
  //     {
  //       name: i18n.t('footer.nav.item.cookies', 'Политика куки'),
  //       link: '/privacy-policy'
  //     },
  //   ]
  // },
  {
    groupTitle: 'Legal and compliance',
    items: [
      {
        name: 'Age restrictions: 18 years and above',
        link: '/age-res'
      },
      // {
      //   name: 'Gambling with a moderation',
      //   link: '/terms'
      // },
      {
        name: 'Terms and conditions',
        link: '/terms'
      }
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      {
        name: i18n.t('footer.nav.item.Casino', 'Слоты и Казино'),
        link: '/games'
      },
    ]
  },
];

export const socialLinks = [
  {
    type: 'facebook',
    link: 'https://www.facebook.com/61559996560689/posts/122103369884333218/?substory_index=425247517024677'
  },
  {
    type: 'instagram',
    link: 'https://www.instagram.com/chezabet.ke?igsh=MWI3MTM2dzJqa2NiZw'
  },
  {
    type: 'tiktok',
    link: 'https://vm.tiktok.com/ZMrWYuQ59/'
  },
  {
    type: 'x',
    link: 'https://x.com/chezabet001/likes'
  },
];

export const downloadApp = {
  ios: undefined,
  android: `${process.env.PUBLIC_URL}/app-release.aab`,
};

export const topTournamentsID = [
  '88637', // Чемпионат Англии. Премьер-лига
  '12821', // Чемпионат Франции. Первая лига
  '110163', // Чемпионат Италии. Серия А
  '127733', // Чемпионат Испании. Примера
  '96463', // Чемпионат Германии. Бундеслига
  '30619', // NHL
  '1246295', // NHL. Награды
  '1364013', // NHL. Итоги чемпионата
  '1890662', // NHL. Специальные ставки
  '2535190',
  '466371',
  '534531',
  '2549929',
  '1189035',
  '1190421',
  '2402865',
  '2543195',
  '2549933',
  '7456',
  '120925',
  '2549403',
  '59629',
  '2543176',
  '1501475',
  '1503583',
  '2549402'
];

export const topCountriesId = [
  '225', // Мир
  '223', // Европа
  '231', // Англия
  '198', // Франция
  '79', // Италия
  '78', // Испания
  '53', // Германия
  '1', // Россия
  '2', // Украина
];

export const topSportsId = [
  '1', // Футбол
  '2', // Баскетбол
  '3' // Хоккей
];

export const batgamesList: GameCardItemType[] = [
  {
    id: 5,
    name: i18n.t('games.betgames.Bet-On-Poker', 'Покер'),
    preview: betGamesPreviews.poker,
    link: '/games/betgames/5',
  },
  {
    id: 17,
    name: i18n.t('games.betgames.Football-Grid', 'Пенальти'),
    preview: betGamesPreviews.football,
    link: '/games/betgames/17',
  },
  {
    id: 7,
    name: i18n.t('games.betgames.Wheel-Of-Fortune', 'Колесо Фортуны'),
    preview: betGamesPreviews.wheel,
    link: '/games/betgames/7',
  },
  {
    id: 12,
    name: i18n.t('games.betgames.6+-Poker', '6+ Покер'),
    preview: betGamesPreviews.poker6,
    link: '/games/betgames/12',
  },
  {
    id: 13,
    name: i18n.t('games.betgames.Andar-Bahar', 'Андар Бахар'),
    preview: betGamesPreviews.anderBaha,
    link: '/games/betgames/13',
  },
  {
    id: 10,
    name: i18n.t('games.betgames.Dice-Duel', 'Дуэль Костей'),
    preview: betGamesPreviews.diceDuel,
    link: '/games/betgames/10',
  },
  {
    id: 11,
    name: i18n.t('games.betgames.Speedy-7', 'Горячая 7'),
    preview: betGamesPreviews.speedy7,
    link: '/games/betgames/11',
  },
  {
    id: 8,
    name: i18n.t('games.betgames.War-Of-Bets', 'Битва Ставок'),
    preview: betGamesPreviews.warOfBets,
    link: '/games/betgames/8',
  },
  {
    id: 6,
    name: i18n.t('games.betgames.Bet-On-Baccarat', 'Баккара'),
    preview: betGamesPreviews.baccarat,
    link: '/games/betgames/6',
  },
  {
    id: 16,
    name: i18n.t('games.betgames.Classic-Wheel', 'Колесо Фортуны Классическое'),
    preview: betGamesPreviews.wheelRNG,
    link: '/games/betgames/16',
  },
  {
    id: 18,
    name: i18n.t('games.betgames.Satta-Matka', 'Сатта Матка'),
    preview: betGamesPreviews.sattaMatka,
    link: '/games/betgames/18',
  },
  {
    id: 3,
    name: i18n.t('games.betgames.Lucky-5', '5 из 36'),
    preview: betGamesPreviews.lucky5,
    link: '/games/betgames/3',
  },
  {
    id: 9,
    name: i18n.t('games.betgames.Lucky-6', '6 из 60'),
    preview: betGamesPreviews.lucky6,
    link: '/games/betgames/9',
  },
  {
    id: 1,
    name: i18n.t('games.betgames.Lucky-7', '7 из 42'),
    preview: betGamesPreviews.lucky7,
    link: '/games/betgames/1',
  },
  {
    id: 26,
    name: 'Instant Lucky 7',
    preview: betGamesPreviews.instantLucky7,
    link: '/games/betgames/26',
  },
];

export const twainSportList: GameCardItemType[] = [
  {
    id: 24,
    name: i18n.t('games.betgames.T-Kick-24/7', 'Футбол'),
    preview: {
      src: tKickPreview,
      webp: tKickPreviewWebp,
    },
    link: '/games/twain-sport/24',
  },
  {
    id: 25,
    name: i18n.t('games.betgames.T-Basket-24/7', 'Баскетбол'),
    preview: {
      src: tBasketPreview,
      webp: tBasketPreviewWebp,
    },
    link: '/games/twain-sport/25',
  },
];

export const crashGames: GameCardItemType[] = [
  {
    id: 27,
    name: 'JetX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/JetX.jpg`,
    link: '/games/smartsoft/JetX/JetX',
  },
  {
    id: 'aviatrix',
    name: 'Aviatrix',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/aviatrix_ax.jpg`,
    link: '/games/aviatrix',
  },
  {
    id: 'aviator_spribe',
    name: 'Aviator',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/aviator_spribe.jpg`,
    link: '/games/vegas/aviator_spribe',
  },
  {
    id: 'rocketman_elbet',
    name: 'Rocketman',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/rocketman_elbet.jpg`,
    link: '/games/vegas/rocketman_elbet',
  },
  {
    id: 27,
    name: 'Skyward',
    preview: {
      src: crashGameSkyward,
      webp: crashGameSkywardWebp,
    },
    link: '/games/betgames/27',
  },
  {
    id: 'plinko_stake',
    name: 'Plinko',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/plinko_stake.jpg`,
    link: '/games/vegas/plinko_stake',
  },
  {
    id: 3,
    name: 'Balloon',
    preview: `${process.env.PUBLIC_URL}/smartsoft/Balloon.jpg`,
    link: '/games/smartsoft/Balloon/Games',
  },
  {
    id: 7,
    name: 'Cappadocia',
    preview: `${process.env.PUBLIC_URL}/smartsoft/Cappadocia.jpg`,
    link: '/games/smartsoft/Cappadocia/Games',
  },
  {
    id: 14,
    name: 'JetX3',
    preview: `${process.env.PUBLIC_URL}/smartsoft/JetX3.jpg`,
    link: '/games/smartsoft/JetX3/XGames',
  },
  {
    id: 15,
    name: 'SpinX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/SpinX.jpg`,
    link: '/games/smartsoft/SpinX/XGames',
  },
  {
    id: 16,
    name: 'CricketX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/CricketX.jpg`,
    link: '/games/smartsoft/CricketX/XGames',
  },
  {
    id: 35,
    name: 'PlinkoX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/PlinkoX.jpg`,
    link: '/games/smartsoft/PlinkoX/Games',
  },
  {
    id: 38,
    name: 'FootballX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/FootballX.jpg`,
    link: '/games/smartsoft/FootballX/Games',
  },
  {
    id: 45,
    name: 'CrazyHuntX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/CrazyHuntX.jpg`,
    link: '/games/smartsoft/CrazyHuntX/XGames',
  },
  {
    id: 47,
    name: 'SlicerX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/SlicerX.jpg`,
    link: '/games/smartsoft/SlicerX/XGames',
  },
  {
    id: 48,
    name: 'TowerX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/TowerX.jpg`,
    link: '/games/smartsoft/TowerX/XGames',
  },
  {
    id: 51,
    name: 'HunterX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/HunterX.jpg`,
    link: '/games/smartsoft/HunterX/Games',
  },
  {
    id: 52,
    name: 'HelicopterX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/HelicopterX.jpg`,
    link: '/games/smartsoft/HelicopterX/XGames',
  },
];

export const tvbetList: GameCardItemType[] = [
  {
    id: 10,
    name: '1Bet',
    preview: tvBetPreviews.onebet,
    link: '/games/tvbet/10'
  },
  {
    id: 12,
    name: 'Lucky6',
    preview: tvBetPreviews.lucky6,
    link: '/games/tvbet/12'
  },
  {
    id: 2,
    name: 'Wheelbet',
    preview: tvBetPreviews.wheel,
    link: '/games/tvbet/2'
  },
  {
    id: 6,
    name: '5Bet',
    preview: tvBetPreviews.bet5,
    link: '/games/tvbet/6'
  },
  {
    id: 7,
    name: '7Bet',
    preview: tvBetPreviews.bet7,
    link: '/games/tvbet/7'
  },
  {
    id: 9,
    name: 'Keno',
    preview: tvBetPreviews.keno,
    link: '/games/tvbet/9'
  },
  {
    id: 23,
    name: 'Fast Keno',
    preview: tvBetPreviews.fastkeno,
    link: '/games/tvbet/23'
  },
  {
    id: 19,
    name: 'BlackJack',
    preview: tvBetPreviews.blackjack,
    link: '/games/tvbet/19'
  },
  {
    id: 3,
    name: 'Poker',
    preview: tvBetPreviews.poker,
    link: '/games/tvbet/3'
  },
  {
    id: 5,
    name: 'War of Elements',
    preview: tvBetPreviews.warofElements,
    link: '/games/tvbet/3'
  },
  {
    id: 22,
    name: 'Roulette',
    preview: tvBetPreviews.roulette,
    link: '/games/tvbet/22'
  },
];

export const fastsportList: GameCardItemType[] = [
  {
    id: 51,
    name: 'Short Football',
    preview: fastsportFootball,
    link: '/games/fastsport/51'
  },
  {
    id: 52,
    name: 'Table tennis',
    preview: fastsportTennis,
    link: '/games/fastsport/52'
  },
  {
    id: 53,
    name: 'Cyberfootball',
    preview: fastsportCyberFootball,
    link: '/games/fastsport/53'
  },
];

export const basketErrors: Record<string, string> = {
  default: i18n.t('backend-errors.basket.default', 'Ошибка постановки ставки'),
  notEnoughMoney: i18n.t('backend-errors.basket.not-enough-money', 'Недостаточно средств'),
  eventBlocked: i18n.t('backend-errors.basket.event-blocked', 'Одно из событий заблокировано или недоступно'),
  coefHasChanged: i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'bet coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'bet is blocked': i18n.t('backend-errors.basket.bet-is-blocked', 'Одна из ставок заблокирована'),
  'event not available': i18n.t('backend-errors.basket.event-not-available', 'Одно из событий не доступно'),
  'Repeated bet': i18n.t('backend-errors.basket.repeated-bet', 'Вы уже делали ставку на данный исход. Повторные ставки запрещены.'),
  'Not enough money': i18n.t('backend-errors.basket.not-enough-money', 'Недостаточно средств'),
  'Event has finished or is not available': i18n.t('backend-errors.bet.event-has-finished-or-is-not-available', 'Событие завершилось или недоступно'),
  'Coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'Basket is empty': 'Корзина пуста',
  'Bet amount limited': 'Сумма ставки ограничена'
};

export const addToBasketErrors: Record<string, string> = {
  default: i18n.t('backend-errors.bet.default', 'Неизвестная ошибка'),
  'Bet outcome is blocked': i18n.t('backend-errors.bet.bet-outcome-is-blocked', 'Ставка заблокирована'),
  'Item already in basket': i18n.t('backend-errors.bet.item-already-in-basket', 'Ставка уже добавлена в корзину'),
  'Multiple bets for same event': i18n.t('backend-errors.bet.multiple-bets-for-same-event', 'Другая ставка на это событие уже в корзине'),
  'Event has finished or is not available': i18n.t('backend-errors.bet.event-has-finished-or-is-not-available', 'Событие завершилось или недоступно'),
  'Repeated bet': i18n.t('backend-errors.bet.repeated-bet', 'Вы уже делали ставку на данный исход. Повторные ставки запрещены.'),
};

export const defaultRuPhoneCode = {
  code: '+7',
  country: 'RU',
};

export const phoneCountryCodes = [
  {
    code: '+254',
    country: 'KE',
  },
];

export const currencies = ['KES'];
