import i18n from 'i18n/i18n';
import { SiteSection } from 'types/siteSections';
import { routes } from 'utils/routes';

export type PageData = {
  link: string;
  title: string;
  id: SiteSection;
};

export const mainPageLinks: PageData[] = [
  // {
  //   link: '/home',
  //   title: i18n.t('main.nav.Home', 'Главная'),
  //   id: 'home',
  // },
  {
    link: '/crash-games',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 'crashGames',
  },
  // {
  //   link: '/crash-games',
  //   title: i18n.t('main.nav.Crash', 'Краш игры'),
  //   id: 'crashGames',
  // },
  {
    link: '/highlights',
    title: i18n.t('main.nav.Highlights', 'Хайлайты'),
    id: 'highlights',
  },
  {
    link: '/line/sport/1/Football',
    title: 'Football',
    id: '1',
  },
  {
    link: '/games/vegas/aviator_spribe',
    title: 'Aviator',
    id: 'aviator_spribe',
  },
  {
    link: '/promotions',
    title: i18n.t('main.nav.Promotions', 'Промоакции'),
    id: 'promotions',
  },
  {
    link: '/games',
    title: i18n.t('main.nav.Casino', 'Казино'),
    id: 'casino',
  },
  // {
  //   link: '/download-app',
  //   title: i18n.t('main.nav.download-app', 'Download app'),
  //   id: 'download-app',
  // },
  {
    link: `${process.env.PUBLIC_URL}/app-release.aab`,
    title: i18n.t('main.nav.download-app', 'Download app'),
    id: 'download-app',
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 'live',
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 'line',
  },
];

export const mainPageLinksMobile: PageData[] = [
  // {
  //   link: '/home',
  //   title: i18n.t('main.nav.Home', 'Главная'),
  //   id: 'home',
  // },
  {
    link: '/crash-games',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 'crashGames',
  },
  // {
  //   link: '/crash-games',
  //   title: i18n.t('main.nav.Crash', 'Краш игры'),
  //   id: 'crashGames',
  // },
  {
    link: '/highlights',
    title: i18n.t('main.nav.Highlights', 'Хайлайты'),
    id: 'highlights',
  },
  {
    link: '/line/sport/1/Football',
    title: 'Football',
    id: '1',
  },
  {
    link: '/games/vegas/aviator_spribe',
    title: 'Aviator',
    id: 'aviator_spribe',
  },
  {
    link: '/promotions',
    title: i18n.t('main.nav.Promotions', 'Промоакции'),
    id: 'promotions',
  },
  {
    link: '/games',
    title: i18n.t('main.nav.Casino', 'Казино'),
    id: 'casino',
  },
  // {
  //   link: '/download-app',
  //   title: i18n.t('main.nav.download-app', 'Download app'),
  //   id: 'download-app',
  // },
  {
    link: `${process.env.PUBLIC_URL}/app-release.aab`,
    title: i18n.t('main.nav.download-app', 'Download app'),
    id: 'download-app',
  },
  // {
  //   link: routes.live.path,
  //   title: i18n.t('main.nav.Live', routes.live.title),
  //   id: 'live',
  // },
  // {
  //   link: routes.line.path,
  //   title: i18n.t('main.nav.Line', routes.line.title),
  //   id: 'line',
  // },
];
