import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import TextContent from 'components/shared/TextContent/TextContent';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';

import './TermsPage.scss';

function TermsPage() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const { tittle } = useParams();
  // useEffect(() => {
  //   if (tittle !== 'main' && tittle !== undefined) {
  //     navigate(tittle);
  //   }
  // }, [tittle]);

  return (
    <div className="terms-page">
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <TextContent className="terms-page__content">
        <h1>TERMS AND CONDITIONS</h1>

        <h2>1. These terms and conditions</h2>
        <ul>
          <li>
            1.1 What these terms cover. These Terms and Conditions shall be valid for all betting games offered by CHEZABET, by using our SMS or visiting any section of our Website.
          </li>
          <li>
            1.2 Why you should read them. Please read these terms and conditions carefully. These terms tell you who we are, how we will provide products and services to you, how you and we may change or end the contract, what to do if there is a problem and other important information. We have highlighted key points in bold but you should read the entire terms and conditions to fully understand them. If you have any questions about these terms, please contact our Solutions Team.
          </li>
        </ul>

        <h2>2. Information about us</h2>
        <ul>
          <li>2.1 Who we are. We are CHEZAMONSTA Limited (trading as CHEZABET)</li>
          <li>
            2.2 Our license. CHEZAMONSTA Limited is licensed by the Betting Control and Licensing Board of Kenya, under the Betting, Lotteries and Gaming Act, Cap 131, Laws of Kenya.
          </li>
          <li>
            2.3 CHEZABET. References in these terms and conditions to “CHEZABET”, “we”, “us” or “our” will be to CHEZAMONSTA Limited.
          </li>
        </ul>

        <h2>3. How to contact us</h2>
        <ul>
          <li>
            3.1 How to contact us. If you need to contact us, please speak to someone in our Solutions Team by Telephone: +254 711291291
          </li>
          <li>
            3.2 How we may contact you. If we have to contact you, we will do so by telephone you provided to us on registration.
          </li>
        </ul>

        <h2>4. Our contract with you</h2>
        <ul>
          <li>
            4.1 These terms apply to any use of our services, and additional terms may also apply on promotional items. By visiting our website and/or by registering and/or using the services, you agree to be, and are, bound by:
            <ul>
              <li>(a) these terms and conditions; and</li>
              <li>(b) the Privacy Policy; and</li>
              <li>(c) the Betting Rules;</li>
              <li>
                (d) the virtual game rules which apply to each virtual game and can be found within each virtual game, and which include the game maximum win;
              </li>
              <li>
                (e) any specific conditions which are stated as applying (e.g. If you are entering a promotion, the promotion terms and conditions); and
              </li>
              <li>
                (f) all such other of our terms and conditions, rules or policies as they relate to any applicable services promotions, bonuses, special offers or any other aspect of your use of the services from time to time and are deemed to have accepted and understood all of the above.
              </li>
            </ul>
          </li>
          <li>
            4.2 All of the terms and conditions, rules and policies referred to in clause 4.1 shall be collectively referred to as the “terms and conditions”.
          </li>
          <li>
            4.3 By using our services, you will be deemed to accept these terms and conditions, including any amendments made to them. If you do not agree to accept and be bound by these terms and conditions, you should stop using our services and website.
          </li>
        </ul>

        <h2>5. Identity of customers</h2>
        <p>
          Your phone number is your identity. We use mobile phone numbers to uniquely identify customers and their account details. We strongly encourage all customers to sign up for identity programs administered by Mobile phone service providers.
        </p>
        <ul>
          <li>
            5.1 If you already have an existing CHEZABET account, you will not be permitted to open a new account with CHEZABET.
          </li>
          <li>
            5.2 CHEZABET accounts, including any CHEZABET promotions, are limited to one per person.
          </li>
          <li>
            5.3 If we believe, in our sole and absolute discretion, that you have:
            <ul>
              <li>(a) registered and/or used more than one account with us; and/or</li>
              <li>
                (b) colluded with one or more other individuals using a number of different CHEZABET accounts to back the same combination of selections (regardless of whether or not such bets are made separately, on different days or across a range of different prices) in an attempt to exceed the maximum pay-out or take advantage of any winnings or bonuses not otherwise available for an individual account holder across linked accounts, we will be entitled to withhold any winnings you may make from those actions and freeze your account.
              </li>
            </ul>
          </li>
        </ul>

        <h2>6. Before you open an account with us</h2>
        <ul>
          <li>
            6.1 We require you to make certain statements before opening an account. When you register an account with us you are agreeing, warranting and representing that:
            <ul>
              <li>
                (a) you are at least (i) 18 years of age and above; or (ii) the age at which gambling is legal under any law that applies, whichever is the greater (for example, the applicable law says you must be 18 years old to gamble, then you will need to be 18). We retain the right to ask for proof of age documentation from any applicant or customer at any time and we can void any transactions made with minors;
              </li>
              <li>
                (b) you will provide accurate registration information when opening your account, which will include without limitation your correct personal telephone number. You must inform us of any changes to these details;
              </li>
              <li>
                (c) you are legally capable of entering into binding contracts, including these terms and conditions and any bets you place;
              </li>
              <li>
                (d) you are opening your account solely for your personal use, and that you are acting on your own behalf and not as an agent on behalf of a third party;
              </li>
              <li>
                (e) you will not try to sell or in any way transfer the benefit of your account to any third party and nor will you acquire or attempt to acquire an account which has been opened in the name of a third party;
              </li>
              <li>
                (f) you are entirely responsible for complying (and that you comply) with laws concerning betting and gaming prior to opening an account, placing any bets, stakes or wagers or using our services. If you are located where use of a particular service is prohibited, you must not;
              </li>
              <li>(i) register with us for the applicable service;</li>
              <li>(ii) attempt to use that service; or</li>
              <li>(iii) use your payment method to undertake betting or gaming with us for the unlawful service;</li>
              <li>(g) you are not prohibited for any reason from betting with us or from using the services; and</li>
              <li>
                (h) you are not currently subject to a self-exclusion from a CHEZABET account and neither we nor any other operator has excluded you, from gambling.
              </li>
            </ul>
          </li>
          <li>
            6.2 You will commit a criminal offence if you are aged under 18 and gamble in Kenya. You must not access our services to gamble if you are underage.
          </li>
          <li>
            6.3 The availability of our services does not constitute an offer, solicitation or invitation by us for the use of our services in any jurisdictions in which such use is prohibited by law. If you use our services in breach of any laws that apply, we will not be liable to you for such use.
          </li>
          <li>
            6.4 You must keep your personal details up-to-date. If you fail to do so, it may result in your bets or payment being declined or issued incorrectly. You can contact our CHEZABET Customer Service Team to update your details.
          </li>
          <li>6.5 You may not transfer any funds from one CHEZABET account to another account for any reason.</li>
          <li>
            6.6 If you breach any of the terms in this clause:
            <ul>
              <li>(a) we may freeze your account and cancel any bet you may have placed;</li>
              <li>(b) we will not be obliged to pay any winnings which might otherwise have been payable in respect of any bet you placed; and</li>
              <li>(c) we may refer the matter to the relevant authorities.</li>
            </ul>
          </li>
          <li>
            6.7 You agree that we are entitled to carry out electronic verification checks on you when you open an account with us. By accepting these terms and conditions and/or registering to use the sms or website you agree that we are entitled to conduct any and all such identification, credit, fraud and other verification checks from time to time. You agree that you will provide us with all information that we require in connection with such verification checks. We will be entitled to suspend or restrict your account in any way that we think is appropriate, until we have completed those verification checks to our satisfaction.
          </li>
          <li>
            6.8 In addition to electronic verification checks, we may carry out manual checks to confirm proof of age and identity. We may ask you to submit a copy of your identity documents to our Solutions Team to enable them to confirm your identity.
          </li>
          <li>
            6.9 We may provide your details to certain third parties for verification. As part of the registration and verification process, we may supply your information details to authorized credit reference agencies or fraud tool suppliers to confirm your identity and payment provider details. You agree that we may process such information in connection with your registration and verification.
          </li>
        </ul>

        <h2>7. Restricted territories</h2>
        <ul>
          <li>
            7.1 Internet gambling may not be legal in some jurisdictions. You understand and accept that the we cannot provide you with any legal advice or assurances in respect of your use of our services and so we make no representations whatsoever as to the legality of the services in your jurisdiction. You should check the relevant laws in your jurisdiction before registering with us and using our services.
          </li>
          <li>
            7.2 Due to the above, we are not permitted to allow persons who are located in certain territories to open accounts with us (and we do not permit accounts to be accessed and operated in those territories). The territories which are prohibited may be changed by us from time to time, with or without prior notice to you.
          </li>
        </ul>

        <h2>8. Keeping your account secure</h2>
        <ul>
          <li>
            8.1 You must keep your account username and password confidential. We will not be responsible for any losses that may arise as a result of misuse of your username or password or from any unauthorized access to your account.
          </li>
          <li>
            8.2 If you have lost or forgotten any of your account details, please contact us as soon as possible. You must inform us immediately if you believe your account details are being misused by anyone so that we can suspend your account and prevent any further unauthorized access.
          </li>
          <li>
            8.3 We would suggest using a secure password storage facility to store any passwords rather than using the automatic password memory on your browser.
          </li>
        </ul>

        <h2>9. How your funds will be held</h2>
        <ul>
          <li>
            9.1 To play our games and place bets, you need to deposit funds. Your funds will be held in a separate bank account from all our other business accounts. However, if there was ever a situation where we became insolvent, your funds would not be considered separate to the other company assets and you may not receive all your funds back.
          </li>
          <li>
            9.2 Your funds have a basic level of protection. This level of protection is decided by us and meets the Betting Control and Licensing Board’s requirements for the protection of customer funds.
          </li>
          <li>
            9.3 Details about the protection levels. We are required by our license to inform you about what happens to funds we hold on account for you in the event of insolvency.
          </li>
          <li>
            9.4 If you are unsure about how your funds are held, you can contact our Solutions Team. You should make sure you are comfortable with this level of protection before you deposit any funds with us.
          </li>
        </ul>

        <h2>10. Deposits and withdrawals</h2>
        <ul>
          <li>
            10.1 To play our games and place bets, you need to deposit funds. Deposits can be made in Kenya Shillings through Mpesa Paybill Number 864343 and the Account Number should be your Phone Number that you registered with.
          </li>
          <li>
            10.2 You are only permitted to use payment methods which are in your own name. You are not permitted to use any payment method belonging to a third party (e.g. you cannot use another phone number). Any third-party deposits will be considered invalid (and any winnings arising from deposit will be voided).
          </li>
          <li>
            10.3 We may void your winnings if you have made deposits with the intention of reversing bets using the deposit which you then cancelled. If any deposit does not reach us or is cancelled by you or a third party, we will be entitled to void all bonuses, free bets and winnings accrued or awarded to your account as a result of that deposit if we believe, in our reasonable discretion, that the deposit was made with the sole intention to reverse bets obtained using the deposit which you then cancelled.
          </li>
          <li>
            10.4 We will carry out money checks on your deposits. As part of our internal policies we make checks on the source of any funds placed on deposit by customers. If these checks do not provide us with sufficient information about you, we may request further information from you (including about your source of funds). You agree to promptly provide this information, when requested to do so. We reserve the right, acting reasonably, to suspend or terminate any account where we cannot satisfy ourselves that the source of funds is legitimate or where sufficient information has not been provided.
          </li>
          <li>
            <p>
              10.5 (i) Minimum and maximum account deposits will apply. We would like to draw your attention to the fact that minimum and maximum deposits will apply. Minimum Deposit Amount is 10 Shillings and Maximum deposit amount per transaction as per your service provider, MPESA 140,000 and maximum deposit amount per day 200,000
            </p>
            <p>
              (ii) Maximum and Minimum Withdrawal. We also would like to draw your attention that there is a Minimum withdrawal per day of ksh 100 and a Maximum withdrawal limit per day of ksh300,000.
            </p>
          </li>
          <li>
            10.6 You cannot make reversals to your account. You are not permitted to make any reversals or otherwise cancel any deposits into your account and if we incur any loss as a result of this activity, you will be required to reimburse us in full on demand.
          </li>
          <li>
            10.7 We will not pay you interest on any of your account balances. We will not accept credit under any circumstances. It is your responsibility to maintain sufficient funds in your account to place any bets you wish to make. We will not be responsible for any missed bets in the event you need to deposit funds before placing a bet. We also need to reserve the right to void any bet which may have been inadvertently accepted if your account does not have sufficient funds to cover the whole of the bet and/or to recover the amount of any shortfall.
          </li>
          <li>
            10.8 Pricing. Customers betting using SMS incur low charges of Ksh2.5 per sms. Minimum Bet Amount on CHEZABET is Ksh 1 on sports betting both on Single and Multi-bet. Jackpot bet amount is Ksh 20 per jackpot bet.
          </li>
          <li>
            10.9 You may withdraw funds from your account if:
            <ul>
              <li>
                (a) all payments made into your account have been confirmed as cleared, have not been reversed or otherwise cancelled and limit has not been exceeded in the last 24hrs;
              </li>
              <li>
                (b) any identity or other know-your-customer checks we are required to conduct or other obligations have been completed to our satisfaction. Please also note that, where we have requested information from you to verify your identify and/or source of funds, any delay in providing this information may cause an additional delay when withdrawing funds;
              </li>
              <li>
                (c) you accept that all transactions may be checked to prevent money laundering and that any transactions made by you which we deem suspicious, may be reported to the appropriate authorities. We reserve the right to decline and/or reverse the withdrawal of unspent or otherwise unused deposits until the verification of these funds is deemed satisfactory, including where deposits have been made and withdrawn immediately with no game play; and
              </li>
              <li>
                (d) there is currently no ongoing investigation into:
                <ul>
                  <li>(i) a game defect involving a game which you have played which requires a temporary freeze on withdrawal of funds;</li>
                  <li>
                    (ii) a situation where there is evidence of betting irregularities (including any attempt to exceed the maximum pay-out or take advantage of any winnings or bonuses not otherwise available), that the integrity of an underlying event has been compromised, the price may have been manipulated, match fixing may have taken place, an individual or individuals are suspected of acting either in conspiracy or concert in respect of a bet;
                  </li>
                  <li>
                    (iii) a situation where there is evidence that the rules of a sports body may have been breached; or (iv) a situation where there is evidence that you have materially breached these terms and conditions, including by acting fraudulently, unlawfully or by engaging in improper activity (including cheating, collusion or criminal activity);
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            10.10 Our solution team may need to cancel withdrawals pending an investigation. There may be occasions where our solution team need to cancel withdrawals in order to carry out additional checks. These funds will be returned to your account pending the investigation but if we discover any irregularities during our investigation, we reserve the right to withhold funds. Responsibility for any funds lies with the account holder and any bets made with them will stand.
          </li>
          <li>
            10.11 If you wish to withdraw a lower amount than our online system permits, you will need to contact our Solutions Team. If the amount you wish to withdraw is below the amount accepted via the sms or website, which will be shown to you at the time you withdraw, you will still be able to withdraw your funds but you will need to contact our Solutions Team to assist.
          </li>
          <li>
            10.12 You are responsible for declaring winnings to tax authorities. If required, it is your responsibility to declare any funds withdrawn, including winnings, to your local tax or other authorities.
          </li>
          <li>
            10.13 You are responsible for reporting any mistakes with your withdrawals. Whilst every effort is made to avoid mistakes, we cannot take any responsibility or liability for errors or omissions you make when you are withdrawing your funds. If any funds are deposited or credited incorrectly, it is your responsibility to inform us and we will aim to resolve it.
          </li>
          <li>
            10.14 You are able to set your own deposit limits. If you would like to set yourself specific limits, please contact our solutions team via telephone or email for details on how to do this.
          </li>
          <li>
            10.15 Withdrawals will be made to the same payment method as the initial deposit. If this is not possible, you will be contacted to make alternative arrangements.
          </li>
          <li>
            10.16 You must notify us of any changes to your payment method details. If you do not update us, your deposits may be declined. You must also inform us if your Simcard is lost or stolen or if you have been a victim of any identify fraud on your payment method account and we will stop further bets being accepted from your account until you notify us of further instructions.
          </li>
        </ul>

        <h2>11. Placing your bets</h2>
        <ul>
          <li>
            11.1 Acceptance and validation of bets. All football bets are settled on 90 minutes’ play (also referred to as Full-Time or Normal Time). This includes time added by the referee for injuries and other stoppages. This does not include scheduled extra time or penalty shoot-outs, if played. In matches where penalty shoot outs or extra time are due to take place, all bets are settled on 90 minutes unless an outright price was specifically requested and confirmed at the time the bet was placed.
          </li>
          <li>
            11.2 There will be limits on the amount you can bet. You may only bet up to
            <ul>
              <li>(i) the amount held in your account or</li>
              <li>
                (ii) Maximum betting amount for a single or multibet is Ksh.70000. We will determine in our discretion what the minimum and maximum bet selection is for each bet and this will be made clear on our website before you place your bet.
              </li>
            </ul>
          </li>
          <li>
            11.3 You should get familiar with how bets and games are operated. We will not be responsible under any circumstances if you place a bet or play a game and have not fully understood the terms or how it is operated. We would recommend that you familiarize yourself with standard betting and gaming terminology. If you are in any doubt about how a game works, please contact a member of our Solutions Team.
          </li>
          <li>
            11.4 You confirm that you are fully aware that there is a risk of losing money when gambling. By using our services, you acknowledge that you are fully responsible for any losses you may make. You agree that your use of the services is at your sole option, discretion and risk. In relation to your gambling losses you shall have no claims whatsoever against us or any of our group companies or any of our respective directors, officers or employees.
          </li>
        </ul>

        <h2>12. Payment of winnings</h2>
        <ul>
          <li>
            12.1 Once winnings are available in your account, you will be able to withdraw them. All cleared winnings will be credited to your account. These will be available to be withdrawn, subject to the above terms and conditions relating to withdrawal.
          </li>
        </ul>

        <h2>13. Maximum payouts</h2>
        <ul>
          <li>
            13.1 Withholding on Winnings
            <p>
              All winnings shall be subject to deduction of tax (“Withholding Tax”) as per the Income Tax Act under section 35 (1) (i), (3) (h) and Kenya Gazette Notice &#123;Supplement No. 121 (Acts No. 9)&#125; published on the 21st September 2018.That the withholding tax percentage to be deducted is 20% as per the tax rate prescribed by the Government of Kenya. This will be deducted from the total payout (Stake + Winnings)
            </p>
            <p>
              Example; if you stake Ksh. 100 on odds of 1.5 and win Ksh. 150, then the 20% will be deducted from Ksh. 50 (Winnings) amounting to Ksh. 10 so total payouts are ksh. 140. Withholding tax which will be remitted by the company to KRA.
            </p>
          </li>
          <li>
            13.2 All bets and wagers are subject to maximum payouts. It is your responsibility to ensure that you are comfortable that a bet you place, if successful, may exceed the maximum payout limit. The maximum winning amount for both single and multi-bet is Ksh.500,000
          </li>
        </ul>

        <h2>14. Free bet restrictions</h2>
        <ul>
          <li>14.1 If you have received a free bet, the following restrictions will apply.</li>
          <li>
            14.2 Your free bet will only be valid on sports. Unless otherwise stated in any applicable promotional terms, when you receive your free bet, you will be able to use it to place a bet on any sporting event but you will not be able to use it on any other sections of the website, for example on virtual sports or Jackpot.
          </li>
          <li>
            14.3 Your free bets will be added to your account once the system has been updated. If your free bet is due to be received after bet settlement, then your free bet will usually be credited within one hour of bet settlement, unless otherwise specified in any applicable promotional terms. If you have not received your free bets within the time specified in any applicable promotional terms or as otherwise notified to you, you can contact our Solutions Team.
          </li>
          <li>
            14.4 Some bet types cannot be used. Unless otherwise stated in any promotional terms, your free bet cannot be used on the following bet types: Jackpot and virtual sports bets. You should check the Betting Rules to understand how different bet types work.
          </li>
          <li>
            14.5 When you place any bet after receiving your free bet, you will be given the option to use your free bet. If you do not select to use your free bet, it will be retained on your account until it expires and any bets will be made using funds from your account.
          </li>
          <li>
            14.6 Your free bet can only be redeemed in full. This means that if you place a bet which is less than the amount of the free bet, you will not receive any change and no additional free bets will be awarded to you. Where you have received multiple free bets, they can only be redeemed in increments of Ksh30. You can spend these in separate Ksh30 bets and each Ksh30 must be redeemed in full. This means that if you place a bet which is less than Ksh30 using one of your free bets, you will not receive any change and no additional free bets will be awarded to you to replace that free bet.
          </li>
          <li>
            14.7 Your free bet cannot be redeemed for cash at any time and must be used to place other sports bets. Once you have placed your free bet, any winnings you have made from your free bet will automatically be transferred to your account balance. You will not be entitled to any return of your free bet stake.
          </li>
          <li>
            14.8 If your free bet is voided, you will not be entitled to any refund and no additional free bets will be awarded to you.
          </li>
          <li>
            14.9 Your free bet has an expiry date. If you do not use your free bet within 24 hours of receiving it (or as specified in any applicable promotion), your free bet will automatically expire and be deleted from your account and you will no longer be able to use your free bet.
          </li>
          <li>14.10 You cannot use your free bet as a qualifying bet for any promotions.</li>
        </ul>

        <h2>15. Upgraded odds rules</h2>
        <ul>
          <li>
            15.1 Upgraded odds may have a maximum bet limit. The maximum bet limit will be shown on the banner or in your bet slip (as applicable). If you want to place a bet greater than the maximum bet permitted for those upgraded odds, then you will need to place these at regular odds.
          </li>
          <li>15.2 Upgraded odds may have stake restrictions. Any stake restrictions will be shown in your bet slip.</li>
          <li>
            15.3 Your upgraded odds bet may sometimes be restricted to a single selection. If this applies, it will be stated on the key terms in the banner or in the bet slip.
          </li>
          <li>
            15.4 Any upgraded odds will have limited availability so once the promotion is removed from the website, you will no longer be able to take advantage of this promotion.
          </li>
        </ul>

        <h2>16. Use of our website</h2>
        <ul>
          <li>
            16.1 Using our SMS or website may incur data charges. You accept that visiting our website outside of a Wi-Fi network may result in data charges from your mobile network provider. We will not be responsible for any data charges incurred as a result of your using our website.
          </li>
          <li>
            16.2 Game rules for individual games can be found within the rules section of the website. Links to these will be provided before you play any games.
          </li>
        </ul>

        <h2>17.Price Changes</h2>
        <p>
          All prices are subject to change and may, on occasion, be restricted to certain stake levels. The prices offered via our different business channels may vary.
        </p>

        <h2>18. Void Bet</h2>
        <p>
          “Void Bet” means the bet is null or invalid. This occurs when an event is postponed/cancelled, or when it has started but not finished within the period specified in our policy. If a game has been cancelled or postponed there is always 24 hours wait until the match will be set as void. Once the match has been set as void (with odd 1.00) the rest of the winning ticket will then be paid out. If a selection in a single bet is made void the stake will be returned. Void selections in multiple bets will be treated as non-runners and the stake will run onto the remaining selections in the bet.
        </p>

        <h2>19. 90-Minute Football Bets</h2>
        <p>
          What is meant by ’90-minute Betting’, ‘Full Time’ and ‘Normal Time’? Adult football matches are played over a 90-minute period, and this period can be called ’90-minutes’, ‘Full Time’ or ‘Normal Time’. All football bets, unless specifically stating they are for Outright, To Qualify, Extra Time or Penalties markets, will be settled on the result after Normal Time (90 minutes). During the game, the referee may stop the game for short periods for substitutions, or if a player gets injured, and at the end of each 45-minute half the referee adds however many minutes he feels is required to account for any of these stoppages. This additional time is called “Injury time”. Full Time and Normal Time are terms for the full 90-minute period, plus any ‘Injury Time’ that the referee chooses to add. Full Time and Normal Time DO NOT include Extra Time. Some matches may have a FT period of eighty (80) minutes or LESS as per e.g. FIFA regulations such matches include; U/16, U/17, Women Seniors etc.
        </p>

        <h2>20. Abandoned Matches</h2>
        <p>
          If a match is abandoned after it has commenced, all bets on that match will be made void except where settlement has already been determined. For example, where the first goal has been scored by a named player the First Goal scorer and Time of First Goal markets, amongst others, will stand.
        </p>

        <h2>21. Postponed/Re-Arranged Matches</h2>
        <p>
          A postponed match is void unless it is re-scheduled to commence within 24 hours of the original start time and this is confirmed within 12 hours of the original start time. In such circumstances where (a) void match(es) is/are included in an accumulator the bet will be settled on the remaining selections.
        </p>

        <h2>22. Prices Fluctuation</h2>
        <p>
          All prices are subject to fluctuation up to the kick-off. All football bets will be settled using CHEZABET website at the time the bet is placed.
        </p>

        <h2>23. Contingencies in Match</h2>
        <p>
          Where we offer various betting opportunities from the same match (e.g. correct score, first player to score etc.) these cannot be combined in accumulative bets where the outcome is related (except where special fixed prices are available).
        </p>
        <p>
          Where an accumulative bet of this type has been accepted in error it will be settled by equally dividing the stake unit where the related outcomes clash.
        </p>

        <h2>24. Withholding Payment</h2>
        <p>
          We reserve the right to withhold payment and to declare bets on an event void if we have evidence that the following has occurred:
        </p>
        <ul>
          <li>– the integrity of the event has been called into question</li>
          <li>– the price(s) has been manipulated</li>
          <li>– match rigging has taken place</li>
        </ul>
        <p>
          Evidence of the above may be based on the size, volume or pattern of bets placed with us across any or all of our betting channels. A decision given by the relevant governing body of the sport in question (if any) will be conclusive. If any customer owes any money to CHEZABET for any reason, we have the right to take that into account before making any payments to that customer.
        </p>

        <h2>25. Results</h2>
        <p>
          In the case of Football and other Sports, bets are settled on the official result published by the relevant event managers or result handlers 3 to 24 hours after the match/event is finished. If the result is not immediately available from an official channel, then the result published by the official governing body immediately the match/event is finished will be used for settling purposes.
        </p>
        <p>
          Any subsequent corrections or amendments to this result will not apply for settlement purposes. CHEZABET will settle all bets rapidly but because of time differences, for some events, bets may be settled overnight or with some delay.
        </p>

      </TextContent>
    </div>
  );
}

export default TermsPage;
